// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-js": () => import("./../../../src/pages/blogs/{Mdx.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolios-index-js": () => import("./../../../src/pages/portfolios/index.js" /* webpackChunkName: "component---src-pages-portfolios-index-js" */),
  "component---src-pages-portfolios-strapi-portfolios-slug-js": () => import("./../../../src/pages/portfolios/{StrapiPortfolios.slug}.js" /* webpackChunkName: "component---src-pages-portfolios-strapi-portfolios-slug-js" */),
  "component---src-pages-reviews-index-js": () => import("./../../../src/pages/reviews/index.js" /* webpackChunkName: "component---src-pages-reviews-index-js" */),
  "component---src-pages-reviews-strapi-reviews-slug-js": () => import("./../../../src/pages/reviews/{StrapiReviews.slug}.js" /* webpackChunkName: "component---src-pages-reviews-strapi-reviews-slug-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tags-template.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

